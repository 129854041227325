import { PUBLIC_DEV_CDN_URL, PUBLIC_DEV_BOT_ID, PUBLIC_DEV_SENTRY_DSN } from "$env/static/public";

export const SENTRY_DSN = PUBLIC_DEV_SENTRY_DSN;
export const CDN_URL = PUBLIC_DEV_CDN_URL || "https://cdn.tupperbox.app";
export const BOT_ID = PUBLIC_DEV_BOT_ID || "431544605209788416";

export const OAUTH_URL = () => `https://discord.com/api/oauth2/authorize?client_id=${BOT_ID}&redirect_uri=${encodeURIComponent(window.location.origin + "/auth/login")}&response_type=code&scope=identify%20guilds`;
export const TRANSPARENT = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkMAYAADkANVKH3ScAAAAASUVORK5CYII=";
export const INVITE_URL = `https://discord.com/oauth2/authorize?client_id=${BOT_ID}&scope=bot%20applications.commands&permissions=275415166016`;
export const SUPPORT_INVITE = "https://discord.gg/Z4BHccHhy3";

export enum ScopeType {
  Global = 0,
  Server = 1,
  Category = 2,
  Channel = 3,
  Thread = 4,
}